<template>
  <div class="row" style="background:#fff; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2); padding-bottom: 25px;">
    <div class="col-md-12 col-sm-12 mt-1">
      <div class="row mt-1">
      </div>
      <div class="row mt-2"> <!-- Tüm Kullanıcılar -- -->
          <div class="col-md-6 mt-1" v-if="Number(this.grade) === 9 || Number(this.grade) === 10">
            <!-- href="https://app.newsomatic.net/login"--- -->
              <a @click="nom()" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsomatic]"></div>
                 <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -- -->
                </div>
              </a>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import services from '@/services/user';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';


export default {
  name: 'platforms',
  data() {
    return {
      grade: '',
      level: '',
      roleType: '',
      querystingtext: '',
      kwquerystingtext: '',
      hexaquerystingtext: '',
      zoom: '',
      checkTime: true,
    };
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  beforeMount() {
    this.setData();
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {
    async timeStamp() {
      const res = await services.getTimeStamp();
      if (this.title === 'demo') return true;
      if (res.data.is_weekend) return false;
      // eslint-disable-next-line
      return (res.data.data.split(' ')[1] > '08:00:00') && (res.data.data.split(' ')[1] < '17:00:00') ? true : false;
    },
    async setData() {
      if (this.userDetails) {
        this.level = this.userDetails.organization.find(x => x.type === 'level').name;
        this.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        this.main = this.userDetails.organization.find(x => x.type === 'main').id;
        this.zoom = this.userDetails.organization.filter(x => x.type === 'grade');
        this.roleType = this.userDetails.role_type;
        this.title = this.userDetails.title;
        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        if (this.userDetails.lang === 'en') {
          this.hexaquerystingtext = `?username=${btoa('steve.jobs')}&password=${btoa('090807')}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }
        this.checkTime = await this.timeStamp();
      }
      this.kwquerystingtext = `?username=${btoa('uesnlkw_teacher')}&password=${btoa('123456')}`;
    },
    goTeacherLink(id) {
      this.$router.push({
        name: 'teachersResources',
        params: {
          id,
        },
      });
    },
    // nom() {
      
    //     // this.newsomaticSchool = this.userDetails.compass_school;
    //     this.newsomaticUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : '';
    //     this.newsomaticPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : '';

    //   if(this.roleType === 'teacher'){
    //     window.open('https://app.newsomatic.net/login', '_blank');
    //   }else{
    //     if(this.newsomaticUser != ''){
    //       swal.fire({
    //         title: 'News-o-Matic',
    //         html:`        
    //         <div class="col-md-12 mt-2 text-center" style="display:table-cells" v-else> <!-- Eğer uesDigital kodu girilmişse -->
    //                     <div class="alert alert-info" role="alert">
    //                       Aşağıdaki kullanıcı bilgileri ile Go to News-o-Matic linkine tıklayarak hesabınıza giriş yapabilirsiniz.
    //                     </div>
    //                     <table class="table table-sm table-striped">
    //                       <tbody>
    //                         <tr>
    //                           <td class="table-primary"><b>User name</b></td>
    //                           <td class="table-light"><b>${this.newsomaticUser}</b></td>
    //                         </tr>
    //                         <tr>
    //                           <td class="table-primary"><b>Password</b></td>
    //                           <td class="table-light"><b>${this.newsomaticPass}</b></td>
    //                         </tr>
    //                       </tbody>
    //                     </table>
    //                 </div>`,
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Go to News-o-matic',
    //       }).then((result) => {
    //         if (result.value) {
    //           window.open('https://app.newsomatic.net/login', '_blank');
    //         }
    //       });

    //     }else {
    //       swal.fire({
    //         title:'Uyarı!',
    //         text: 'Kullanıcı bilgileriniz en kısa sürede burada görünecektir...',
    //         confirmButtonText: 'Kapat',
    //       });
    //     }


    //   }

    // },
    nom() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy";
        const userData = {}
        if (this.userDetails) {
          userData.username = this.userDetails.email;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.role_type = this.userDetails.role_type;
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.class = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          userData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          //userData.user_id = this.userDetails.user_id;
          userData.reading_level= 1;
        }
        //  console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/nom/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
