import uesLms from '../services';
import authService from './auth';

export default {
  async getPackages() {
    try {
      await authService.refreshToken();
      // const res = await uesLms().get('/packages');
      return {
        success: true,
        data: [],
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getAssignments(page, req, type) {
    try {
      await authService.refreshToken();
      let res = null;
      if (type) {
        res = await uesLms().post(`/assignments?page=${page}`, {
          platform_id: 58,
          class_id: req.class_id,
          assignment_ids: null,
          paginate: 999999,
          type: 'individual',
        });
      } else if (req) {
        res = await uesLms().post(`/assignments?page=${page}`, {
          platform_id: 58,
          class_id: req.class_id,
          assignment_ids: null,
          paginate: 999999,
        });
      } else {
        res = await uesLms().post(`/assignments?page=${page}`, {
          paginate: 999999,
        });
      }
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getCollections() {
    try {
      await authService.refreshToken();
      const res = await uesLms().post('/collections');
      if (res.response && res.response.status !== 200) {
        console.log('Collection Error', res.response.data.error);
        res.data = [];
      }
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getScorms(id, page, paginateOrType) {
    try {
      await authService.refreshToken();
      let res;
      if (paginateOrType === 'reader') {
        res = await uesLms(true).post(`/scorms?page=${page}`, {
          type: 'reader',
          paginate: 9999,
        });
      } else if (!paginateOrType) {
        res = await uesLms(true).post(`/scorms?page=${page}`, {
          collection_id: id,
        });
      } else {
        res = await uesLms(true).post(`/scorms?page=${page}`, {
          collection_id: id,
          paginate: paginateOrType,
        });
      }
      return res.data;
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getScormsMyeduclass(id, page, paginateOrType) {
    try {
      await authService.refreshToken();
      let res;
      if (paginateOrType === 'reader') {
        res = await uesLms(true).post(`/scorms/myeduclass?page=${page}`, {
          type: 'reader',
          paginate: 9999,
        });
      } else if (!paginateOrType) {
        res = await uesLms(true).post(`/scorms/myeduclass?page=${page}`, {
          collection_id: id,
        });
      } else {
        res = await uesLms(true).post(`/scorms/myeduclass?page=${page}`, {
          collection_id: id,
          paginate: paginateOrType,
        });
      }
      return res.data;
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getPiriScorms(page) {
    try {
      await authService.refreshToken();
      let res;
        res = await uesLms(true).post(`/scorms/myeduclassUniReaders?page=${page}`);
      return res.data;
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getHistoryScorms() {
    try {
      await authService.refreshToken();
      const res = await uesLms(true).post('/assignment/assignmentScormList', {
        platform_id: 58,
      });
      return res.data;
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async setAssignment(data) {
    try {
      await authService.refreshToken();
      const res = await uesLms().post('/assignment/create', data);
      if (res.data.success === false) {
        return {
          success: false,
          data: res.data.message,
        };
      }
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async deleteAssignments(ids) {
    try {
      await authService.refreshToken();
      /*eslint-disable*/
      let removeSuccess = false;
      let globalRes = '';
      for (const id of ids) {
        const res = await uesLms().post(`/assignment/${id}/deleteassignment`);
        if(res.data.data.success === true) {
          removeSuccess = true;
        } else {
          removeSuccess = false;
          globalRes = res.data.message;
          break;
        }
      }
      if (removeSuccess === false) {
        return {
          success: false,
          data: globalRes,
        };
      }
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getPaginatedScores(id) {
    try {
      await authService.refreshToken();
      const res = await uesLms().post(`/assignment/${id}`);
      return {
        success: true,
        data: res.data.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async loadState(data) {
    try {
      const res = await uesLms().post('/state/load', data);
      return res.data;
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async saveStateScore(data) {
    try {
      const res = await uesLms().post('/state/save', data);
      return res.data;
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getLink() {
    try {
      await authService.refreshToken();
      const res = await uesLms().post(`/packages?page=${1}`, {
        package_type: 'link',
      });
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async editAssignment(id, data) {
    try{
      const res = await uesLms().post(`/assignment/${id}/update`, {
        assignment_name: data.assignment_name,
        assignment_end_date: data.assignment_end_date
      });
      return res;
    } catch (error) {
      return{
        success: false,
        data: error.message,
      };
    }
  },
  async editAssignmentDate(id, data) {
    try{
      const res = await uesLms().post(`/assignment/${id}/update`, {
        assignment_end_date: data.assignment_end_date
      });
      return res;
    } catch (error) {
      return{
        success: false,
        data: error.message,
      };
    }
  },
  async addScorms(id, scorms) {
    try{
      const res = await uesLms().post(`/assignment/${id}/addScorm`, {
        scorms_ids: scorms
      });
      return res;
    } catch (error) {
      return{
        success: false,
        data: error.message,
      };
    }
  }
};
