module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#6f2b8f',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: '7pb6L1hu4HSFlj730e6umtCYbHLdfzw9cbj1iTd85DAulFin',
};
